<template>
  <div>
    <div>
      <input 
        ref="inputExtraSmall"
        type="text" 
        class="h-8 p-2 text-sm rounded-md focus:border-secondary focus:shadow-secondary-round focus:ring-0 hover:shadow-primary-sm text-gray" 
        :class="`${error? 'border-error shadow-error-sm' : 'border-primary'} ${widthFull ? 'w-full' : ''} ${align}`"
        @keypress="keypress"
        @blur="blur"
        @input="handleInput"
        @keydown.enter="onEnter"
        @focus="focus"
        v-model="computedValue"
        />
    </div>
    <div
      v-if="this.$slots['message'] != undefined"
      class="text-xs mt-1"
      :class="[{ 'text-error': error }]"
    >
      <slot name="message"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputExtraSmall',
  props: {
    value: [String, Number],
    label: String,
    size: {
      type: String,
      default: 'large'
    },
    widthFull: Boolean,
    withTitle: String,
    align: String,
    error: Boolean
  },
  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    keypress(e) {
      this.$emit('keypress', e)
    },
    handleInput(e) {
      this.$emit('handleInput', e)
    },
    blur() {
      this.$emit('blur')
    },
    onEnter() {
      this.$emit('onEnter')
      this.$refs.inputExtraSmall.blur()
    },
    focus() {
      this.$emit('focus')
    },
  }
}
</script>