<template>
  <div>
    <ButtonGista class="w-full" type="primary" color="error" @click="changeIsOpen(true)" :disabled="disabled">Re-Negotiation</ButtonGista>
    <ModalConfirm 
      title="Apakah anda yakin?" 
      message="Proses Negosiasi akan dilanjutkan" 
      textButton="Re-Negotiation" 
      :isOpen="isOpen" 
      @changeIsOpen="changeIsOpen($event)" 
      :isBackButton="true"
      @click="clickReNegotiation"
      colorButton="error">
    </ModalConfirm>
  </div>
</template>

<script>
import ModalConfirm from '@/core/components/modal/Confirm'

  export default {
    name: 'ReNegotiation',
    props: ['isOpen', 'disabled'],
    methods: {
      changeIsOpen(val) {
        this.$emit('clickToggle', val)
      },
      clickReNegotiation() {
        this.$emit('click')
        this.changeIsOpen(false)
      }
    },
    components: {
      ModalConfirm
    }
  }
</script>