<template>
  <gista-modal
    width="1/2"
    :value="errObj?.value"
    :hideClose="false"
    customClass="max-w-2xl bg-white"
    :scrollable="true"
    @input="closeModal"
  >
    <div class="flex flex-col w-full p-6">
      <div class="flex flex-col justify-center flex-auto">
        <div class="flex justify-center mb-6">
          <img src="@/core/assets/icons/message/rejected.svg" alt="error" class="w-28" />
        </div>
        <div v-if="errObj.title" class="flex justify-center mb-6">
          <p class="text-xl font-semibold text-error">{{errObj?.title}}</p>
        </div>
        <div v-if="errObj.message" class="flex justify-center mb-6">
          <p class="text-sm text-center whitespace-pre-wrap text-gray">{{errObj?.message}}</p>
        </div>
        <div v-if="errObj.traceid" class="flex justify-end mb-6">
          <p class="text-sm text-gray">traceid: <span class="text-gray-darkest">{{errObj?.traceid}}</span></p>
        </div>
      </div>
      <div class="flex justify-center flex-none">
        <button-gista @click="click" color="error" customClass="w-60">OK</button-gista>
      </div>
    </div>
  </gista-modal>
</template>

<script>
export default {
  name: "ModalError",
  props: {
    errObj: Object
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
  },
  methods: {
    click() {
      this.$emit("click")
      this.closeModal()
    },
    closeModal() {
      this.$emit('clearErrObj')
    }
  },
}
</script>