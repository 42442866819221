<template>
  <button
      class="flex items-center justify-center transition"
      :class="[cursor, sizeButton, buttonColor, customClass]"
      @click="click($event)"
      :disabled="disabled"
    >
      <slot name="icon-left" class="mr-2"></slot>
      <slot></slot>
      <slot name="icon-right" class="mr-2"></slot>
  </button>
</template>

<script>
export default {
  name: 'ButtonExtraSmallSolutip',
  props: {
    type: {
      //primary, secondary,tertiary (refer to figma)
      type: String,
      default: "primary",
    },

    color: {
      //using color stated in tailwind config
      type: String,
      default: "primary",
    },

    isDark: {
      //if type is primary & isDark false, then the text will be gray
      type: Boolean,
      default: true,
    },

    disabled: {
      // if true then the button cannot be clicked
      type: Boolean,
      default: false,
    },

    icon: {
      //if true then padding x & y will same (symetrical)
      type: Boolean,
      default: false,
    },

    rounded: {
      //if true rounded-full
      type: Boolean,
      default: false,
    },

    expanded: {
      // if true -> w-full
      type: Boolean,
      default: false,
    },

    customClass: {
      // to customize button
      type: String,
      default: "",
    },
  },

  computed: {
    cursor() {
      if (this.disabled) return "cursor-not-allowed";
      return "cursor-pointer";
    },

    sizeButton() {
      return `font-semibold ${
        this.icon ? "text-xs w-14 h-12" : "text-xs py-1.5 px-4"
      } ${this.rounded ? "rounded-full" : "rounded-md"} ${
        this.expanded ? "w-full" : ""
      }`;
    },

    textColor() {
      switch (this.type) {
        case "primary":
          return this.isDark ? "text-white" : "text-gray";
        case "secondary":
        case "tertiary":
          return this.disabled
            ? `text-${this.color}-light`
            : `text-${this.color} hover:text-${this.color}-dark active:text-${this.color}-darkest`;
        default:
          return this.isDark ? "text-white" : "text-gray";
      }
    },
    buttonColor() {
      switch (this.type) {
        case "primary":
          return this.buttonColorPrimary();
        case "secondary":
          return this.buttonColorSecondary();
        case "tertiary":
          return this.buttonColorTertiary();
        default:
          return this.buttonColorPrimary();
      }
    },
  },

  methods: {
    click(event) {
      this.$emit("click", event);
    },
    buttonColorPrimary() {
      return this.disabled
            ? `bg-${this.color}-light ${this.textColor}`
            : `bg-${this.color} hover:bg-${this.color}-dark active:bg-${this.color}-darkest ${this.textColor}`;
    },
    buttonColorSecondary() {
      return this.disabled
            ? `bg-white ${this.textColor} border-${this.color}-light`
            : `bg-white hover:bg-${this.color}-lightest ${this.textColor} border-${this.color} hover:border-${this.color}-dark active:border-${this.color}-darkest`;
    },
    buttonColorTertiary() {
      return this.disabled
            ? `bg-gray-lightest ${this.textColor}`
            : `bg-transparent hover:bg-${this.color}-lightest ${this.textColor}`;
    }
  },
}
</script>