<template>
  <div class="flex items-center">
    <div class="w-full" :class="dataTitle? 'tooltip': ''">
      <slot></slot>
      <span v-if="dataTitle" class="tooltiptext font-poppins bg-white text-sm text-gray border border-gray" :class="isLeft? 'tooltiptextleft': 'tooltiptextright'" :style="`${isLeft? `left: ${left};`: `right: ${right};`} top: ${top};`">{{dataTitle}}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TooltipSolutip',
  props: {
    dataTitle: String,
    left: {
      type: String,
      default: '2rem',
    },
    right: {
      type: String,
      default: ''
    },
    isLeft: {
      type: Boolean,
      default: true,
    },
    top: {
      type: String,
      default: '-50%'
    }
  },
}
</script>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  text-align: center;
  padding: 0.375rem 1rem;
  white-space: nowrap;
  border-radius: 0.4rem;
  position: absolute;
  z-index: 100;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip .tooltiptextleft::after {
  content: " ";
  position: absolute;
  top: 0.75rem;
  left: -0.325rem;
  padding: 0.25rem;
  background-color: white;
  border-left-width: 1px;
  border-bottom-width: 1px;
  transform: rotate(45deg);
}
.tooltip .tooltiptextright::after {
  content: " ";
  position: absolute;
  top: 0.75rem;
  right: -0.325rem;
  padding: 0.25rem;
  background-color: white;
  border-left-width: 1px;
  border-bottom-width: 1px;
  transform: rotate(225deg);
}
</style>