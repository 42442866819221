var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('date-picker',{attrs:{"mode":_vm.mode,"is24hr":_vm.is24hr,"is-range":_vm.isRange,"model-config":_vm.modelConfig,"min-date":_vm.minDate,"max-date":_vm.maxDate,"popover":{ visibility: _vm.disabled? 'hidden': 'hover-focus'}},on:{"input":_vm.handleSelected},scopedSlots:_vm._u([{key:"default",fn:function({ inputEvents }){return [_c('div',{staticClass:"flex flex-col"},[(_vm.label != '')?_c('div',[_c('label',{staticClass:"block text-sm text-gray md:text-left mb-1 pr-4 font-medium",class:[{ 'text-error': _vm.error }]},[_vm._v(" "+_vm._s(_vm.label)+" ")])]):_vm._e(),_c('div',{staticClass:"relative"},[_c('input',_vm._g({staticClass:"p-2.5 border text-gray rounded-lg placeholder-gray-light focus:outline-none w-full focus:border-secondary focus:shadow-secondary-round",class:[
              {
                'border-primary hover:shadow-primary-sm': !_vm.error,
                'border-error': _vm.error,
                'pr-10': _vm.withIcon != undefined,
                'pl-2': _vm.withIcon == undefined,
                // 'text-small': size != 'large',
                'border-none bg-gray-lightest cursor-not-allowed': _vm.disabled,
                'border-primary hover:shadow-primary-sm': !_vm.disabled,
              },
              {
                'text-small h-11': _vm.size == 'small',
                'text-base h-12': _vm.size == 'medium',
                'text-base h-14': _vm.size == 'large',
              },
            ],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled},domProps:{"value":_vm.value}},inputEvents)),(_vm.withIcon != undefined)?_c('div',{staticClass:"absolute icon-calendar inset-y-0 flex items-center right-0 pr-4",class:{ 'text-gray': !_vm.error, 'text-error': _vm.error },staticStyle:{"font-weight":"600"}}):_vm._e()])])]}}]),model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}}),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }