<template>
  <div>
    <date-picker
      :mode="mode"
      :is24hr="is24hr"
      :is-range="isRange"
      @input="handleSelected"
      :model-config="modelConfig"
      :min-date="minDate"
      :max-date="maxDate"
      v-model="range"
      :popover="{ visibility: disabled? 'hidden': 'hover-focus'}"
    >
      <template v-slot="{ inputEvents }">
        <div class="flex flex-col">
          <div v-if="label != ''">
            <label
              class="block text-sm text-gray md:text-left mb-1 pr-4 font-medium"
              :class="[{ 'text-error': error }]"
            >
              {{ label }}
            </label>
          </div>
          <div class="relative">
            <input
              class="p-2.5 border text-gray rounded-lg placeholder-gray-light focus:outline-none w-full focus:border-secondary focus:shadow-secondary-round"
              :class="[
                {
                  'border-primary hover:shadow-primary-sm': !error,
                  'border-error': error,
                  'pr-10': withIcon != undefined,
                  'pl-2': withIcon == undefined,
                  // 'text-small': size != 'large',
                  'border-none bg-gray-lightest cursor-not-allowed': disabled,
                  'border-primary hover:shadow-primary-sm': !disabled,
                },
                {
                  'text-small h-11': size == 'small',
                  'text-base h-12': size == 'medium',
                  'text-base h-14': size == 'large',
                },
              ]"
              :value="value"
              v-on="inputEvents"
              :placeholder="placeholder"
              :disabled="disabled"
            />
            <div
              class="absolute icon-calendar inset-y-0 flex items-center right-0 pr-4"
              :class="{ 'text-gray': !error, 'text-error': error }"
              style="font-weight: 600"
              v-if="withIcon != undefined"
            ></div>
          </div>
        </div>
      </template>
    </date-picker>
    <slot></slot>
  </div>
</template>

<script>
/*
  <datepicker-gista
    v-validate="'required'"
    :error="errors.has('end_date')"
    name="end_date"
    mode="date"
    placeholder="Tanggal Masa Selesai"
    size="large"
    label="Masa Selesai*"
    v-model="formInput.end_date"
    :disabled="composite_role.value == ''"
    :min-date="formInput.minDate"
    data-vv-as="Masa selesai"
  >
  </datepicker-gista>
*/

import DatePicker from "v-calendar/src/components/DatePicker";
export default {
  name: "Datepicker2",
  props: {
    minDate: {
      // min date untuk dipilih (refer to documentation v-calendar)
      type: Date,
    },
    maxDate: {
      // max date untuk dipilih (refer to documentation v-calendar)
      type: Date,
    },
    value: {
      type: String,
      default: () => "",
    },
    disabled: {
      type: Boolean,
    },
    is24hr: {
      // refer to documentation v-calendar
      type: String,
    },
    mode: {
      // refer to documentation v-calendar
      type: String,
    },
    placeholder: {
      type: String,
    },
    "with-icon": {
      type: String,
    },
    error: {
      // error flag
      type: Boolean,
    },
    "is-range": {
      type: Boolean,
      default: false,
    },
    // updateSelect: {
    //   type: String,
    // },
    size: {
      // if large -> text-lg, else text-small
      type: String,
      default: "medium",
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY", // Uses 'iso' if missing
      },
      range: '',
    };
  },
  components: {
    DatePicker,
  },
  watch: {
    value() {
      if (this.value) {
        const dateArray = this.value.split('/')
        this.range = new Date(dateArray[2], dateArray[1] - 1, dateArray[0])
      }
    }
  },
  methods: {
    handleSelected(value) {
      this.$emit("input", value);
    },
  },
};
</script>
